import logo from './logo.svg';
import './App.css';
import React from 'react';
import EventList from './components/EventList';
const appVersion = require('../package.json').version;


function App() {
  return (
    <div>
      <EventList/>
      App Ver: {appVersion}
	</div>
  );
}

export default App;
