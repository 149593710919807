import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import './EventList.css'; // Import stylów

function EventList() {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    // Pobranie danych z endpointu przy użyciu Axios
    axios.get('/today')
      .then(response => {
        setEvents(response.data);
      })
      .catch(error => {
        console.error('Wystąpił błąd podczas pobierania danych:', error);
      });
  }, []);

  return (
    <div>
      <h1>Nadchodzące wydarzenia artystyczne</h1>
      <table className="event-table">
        <thead>
          <tr>
            <th>Teatr</th>
            <th>Tytuł</th>
            <th>Data</th>
            <th>Godzina</th>
          </tr>
        </thead>
        <tbody>
          {events.map((event, index) => (
            <tr key={event.id} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
              <td>{event.theater.name}</td>
              <td>{event.title.title}</td>
              <td>{moment(event.zonedDateTime).format('YYYY-MM-DD')}</td>
              <td>{moment(event.zonedDateTime).format('HH:mm')}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default EventList;
